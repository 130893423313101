import React, { useEffect, useState } from "react";
import {
  FaFacebookF,
  FaGithub,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import { TypeAnimation } from "react-type-animation";

export default function Dashboard() {
  return (
    <>
      <div className="dash-container">
        <div className="d-flex">
          {/* <div className="user-img m-5">
            <img src={image1} alt="Avatar" className="circular-image" />
          </div> */}
          <div className="m-5">
            <h1>Hi, I am Manish Parajuli</h1>
            {/* <TypeAnimation
              sequence={[
                "Software Design", // Types 'One'
                2000, // Waits 1s
                "Software Development", // Deletes 'One' and types 'Two'
                2000, // Waits 2s
                "Software Deployment", // Types 'Three' without deleting 'Two'
                2000,
                "",
              ]}
              wrapper="span"
              cursor={true}
              repeat={Infinity}
              style={{
                fontSize: "24px",
                display: "inline-block",
                // fontWeight: "600",
                fontFamily: "'Karla', sans-serif",
              }}
              className="typing-text"
            /> */}
            <p className="mt-3">
              Software Engineer focused on growth and innovation with a strong
              attention to detail.
            </p>
            <ul className="social-media ">
              <li>
                <a
                  href="https://www.linkedin.com/in/manish3477/"
                  target="_blank"
                >
                  <FaLinkedinIn />{" "}
                </a>
              </li>
              <li>
                <a href="https://github.com/manish3477" target="_blank">
                  <FaGithub />
                </a>
              </li>
              <li>
                <a href="">
                  <FaTwitter />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
