import "./App.css";
import Sidebar from "./components/Sidebar";
import { Route, Routes } from "react-router-dom";
import Router from "../src/routes/router";
import { useState } from "react";
import Themchange from "./components/Themchange";
import Navbar from "./components/Navbar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [collapsed, setCollapsed] = useState(false);
  const [image, setImage] = useState(false);
  const [toggled, setToggled] = useState(false);

  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Routes>
        <Route
          exact
          path="*"
          element={
            <>
              <Navbar />
              <div className="d-flex ml-3">
                <Sidebar
                  collapsed={collapsed}
                  toggled={toggled}
                  handleToggleSidebar={handleToggleSidebar}
                  handleCollapsedChange={handleCollapsedChange}
                />
                <main className="main-container">
                  <Router />
                </main>
              </div>

              <Themchange />
            </>
          }
        />
      </Routes>
    </>
  );
}

export default App;
