import React, { useState } from "react";
import {
  FaBars,
  FaChalkboard,
  FaEnvelope,
  FaHome,
  FaListUl,
  FaRegSun,
  FaTimes,
  FaUserAlt,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";

export default function Navbar() {
  const [isToggltActive, setToggleActive] = useState(false);
  const toggleClass = () => {
    setToggleActive(!isToggltActive);
  };
  return (
    <>
      <div className="nav-bar">
        <div className="">
          <h2>Manish</h2>
        </div>
        <div className="hamburger" onClick={toggleClass}>
          {isToggltActive ? <FaTimes /> : <FaBars />}
        </div>
      </div>
      <div className={isToggltActive ? "activetoggle" : "hidetoggle"}>
        <div className="navbar-links">
          <ul className="p-4">
            <li>
              <NavLink
                to="/"
                className={({ isActive }) => (isActive ? "active" : "")}
                onClick={() => {
                  setToggleActive(false);
                }}
              >
                <FaHome className="icon" />
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/about"
                className={({ isActive, isPending }) =>
                  isActive ? "active" : ""
                }
                onClick={() => {
                  setToggleActive(false);
                }}
              >
                <FaUserAlt className="icon" />
                About
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/service"
                className={({ isActive, isPending }) =>
                  isActive ? "active" : ""
                }
                onClick={() => {
                  setToggleActive(false);
                }}
              >
                <FaRegSun className="icon" />
                Service
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/portfolio"
                className={({ isActive, isPending }) =>
                  isActive ? "active" : ""
                }
                onClick={() => {
                  setToggleActive(false);
                }}
              >
                <FaListUl className="icon" />
                Portfolio
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/blogs"
                className={({ isActive, isPending }) =>
                  isActive ? "active" : ""
                }
                onClick={() => {
                  setToggleActive(false);
                }}
              >
                <FaChalkboard className="icon" />
                Blogs
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                className={({ isActive, isPending }) =>
                  isActive ? "active" : ""
                }
                onClick={() => {
                  setToggleActive(false);
                }}
              >
                <FaEnvelope className="icon" />
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
