import React, { useState } from "react";
// import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaMoon, FaSun } from "react-icons/fa";

export default function Themchange() {
  const [theme, setTheme] = useState("day");
  // const mainColor = getComputedStyle(document.documentElement).getPropertyValue(
  //   "--mainColor"
  // );
  // const secondColor = getComputedStyle(
  //   document.documentElement
  // ).getPropertyValue("--secondColor");
  // console.log("mainColor", mainColor);

  const changeTheme = () => {
    if (theme === "day") {
      setTheme("night");
      document.documentElement.style.setProperty("--mainColor", "white");
      document.documentElement.style.setProperty("--secondColor", "black");
      document.documentElement.style.setProperty("--bodymainColor", "#111111");
      document.documentElement.style.setProperty("--btnMain", "#404040");
      document.documentElement.style.setProperty("--spanColor", "#191d2b70");
    } else {
      setTheme("day");
      document.documentElement.style.setProperty("--mainColor", "black");
      document.documentElement.style.setProperty("--secondColor", "white");
      document.documentElement.style.setProperty("--bodymainColor", "#f8f8f8");
      document.documentElement.style.setProperty("--btnMain", "#dfdfdf");
      document.documentElement.style.setProperty("--spanColor", "#d7dce670");
    }
  };
  return (
    <div>
      {" "}
      {/* <OverlayTrigger
        placement="top"
        overlay={<Tooltip>Change Page Theme</Tooltip>}
      > */}
      <div
        className="botIcon"
        onClick={() => {
          changeTheme();
        }}
      >
        {theme === "day" ? <FaMoon /> : <FaSun />}
      </div>
      {/* </OverlayTrigger> */}
    </div>
  );
}
