import React, { useState } from "react";
import { Carousel, Modal } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import halopay from "../../../assets/images/portfolio/halopay.png";

import chatbot from "../../../assets/images/portfolio/chatbot.png";

import neptrip from "../../../assets/images/portfolio/neptrip.png";
import neptrip1 from "../../../assets/images/portfolio/neptrip-1.png";
import neptrip2 from "../../../assets/images/portfolio/neptrip-2.png";

import awss3 from "../../../assets/images/portfolio/cloudfront-s3.jpg";
import ec2 from "../../../assets/images/portfolio/ec2-github.png";

import invoiceme1 from "../../../assets/images/portfolio/invoiceme1.png";
import invoiceme2 from "../../../assets/images/portfolio/invoiceme2.png";
import invoiceme3 from "../../../assets/images/portfolio/invoiceme3.png";

export default function PortfolioList() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [img1, setImg1] = useState();
  const [img2, setImg2] = useState();
  const [img3, setImg3] = useState();
  const [description, setDescription] = useState();
  const [title, setTitle] = useState();
  return (
    <div>
      <div className="container-fluid my-5">
        <h5 className="mt-3">#Design & Development</h5>
        <div className="row">
          <div className="col-xxl-6 col-xl-6 col-xl-6 col-12 mb-3">
            <div
              className="portfolio-card"
              onClick={() => {
                setImg1(neptrip);
                setImg2(neptrip1);
                setImg3(neptrip2);
                setTitle("Frontend Development");
                setDescription(
                  "Developed and designed the frontend of travel website capable of booking hotels and guides,viewing sites and storing customer data."
                );
                handleShow();
              }}
            >
              <img src={neptrip} alt="img1" className="portfolio-img" />
              <p className="mt-3">
                <strong>Travel planner application</strong>
              </p>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-xl-6 col-12 mb-3">
            <div
              className="portfolio-card"
              onClick={() => {
                setImg1(invoiceme1);
                setImg2(invoiceme2);
                setImg3(invoiceme3);
                setTitle("Fullstack Development (MERN)");
                setDescription(
                  "Developed on an application that create invocie, sent them via email and track invoice activity.This application is under development, currenlty working on custom chatbots and data insights."
                );
                handleShow();
              }}
            >
              <img src={invoiceme1} alt="img1" className="portfolio-img" />
              <p className="mt-3">
                <strong>Invoicing application</strong>
              </p>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-xl-6 col-12 mb-3">
            <div
              className="portfolio-card"
              onClick={() => {
                setImg1(chatbot);
                setImg2();
                setImg3();
                setTitle("Python and React Development");
                setDescription(
                  "Developed custom chatbots that can calculate size for clothes, track order and payments."
                );
                handleShow();
              }}
            >
              <img src={chatbot} alt="img1" className="portfolio-img" />
              <p className="mt-3">
                <strong>Custom Chatbot</strong>
              </p>
            </div>
          </div>
        </div>
        <h5 className="mt-3">#Deployment & DevOps</h5>
        <div className="row">
          <div className="col-xxl-6 col-xl-6 col-xl-6 col-12 mb-3">
            <div className="portfolio-card">
              <img src={awss3} alt="img1" className="portfolio-img" />
              <p className="mt-3">
                <strong>Code deployment via aws s3 and cloudFront</strong>
              </p>
              <a href="https://manishparajuli.com/" target="_blank">
                Live Link
              </a>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-xl-6 col-12 mb-3">
            <div className="portfolio-card">
              <img src={ec2} alt="img1" className="portfolio-img" />
              <p className="mt-3">
                <strong>CI/CD pipeline with aws EC2 & GitHub actions</strong>
              </p>
              <div className="d-flex justify-content-around">
                <a
                  href="http://ec2-18-205-23-147.compute-1.amazonaws.com/"
                  target="_blank"
                >
                  Live Link
                </a>
                <a
                  href="https://medium.com/@parajulimanish43/ci-cd-for-mern-application-with-aws-ec2-instance-and-github-actions-b069d58501a5"
                  target="_blank"
                >
                  Documentation
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Header>
          <div className="popClose-btn" onClick={handleClose}>
            <FaTimes />
          </div>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Carousel interval={5000}>
            <Carousel.Item>
              <img src={img1} alt="big-img" className="modal-img" />
            </Carousel.Item>
            {img2 ? (
              <Carousel.Item>
                <img src={img2} alt="big-img" className="modal-img" />
              </Carousel.Item>
            ) : null}
            {img2 ? (
              <Carousel.Item>
                <img src={img2} alt="big-img" className="modal-img" />
              </Carousel.Item>
            ) : null}
          </Carousel>

          <h3 className="mt-3">{title}</h3>
          <p className="mt-1">{description}</p>
        </Modal.Body>
      </Modal>
    </div>
  );
}
