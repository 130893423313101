import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";

import design from "../../../assets/images/icons/design.png";
import development from "../../../assets/images/icons/development.png";
import deployment from "../../../assets/images/icons/deployment.png";
import mmgt from "../../../assets/images/icons/mmgt.png";


export default function ServiceList() {
  const [modalShow, setModalShow] = useState(false);
  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);

  const [title, setTitle] = useState();
  const [data, setData] = useState();
  const [image, setImage] = useState();
  return (
    <div>
      {" "}
      <div className="container-fluid mt-5">
        <div className="row">
          <div className="col-xxl-6 col-xl-6 col-xl-6 col-12 mb-3">
            <div className="display-card business-block">
              <div>
                <img src={design} />
              </div>
              <div>
                <h3>Web Design</h3>
                <p>
                  My work involves conceptualising, developing, and implementing
                  a website's visual and interactive elements.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-xl-6 col-12 mb-3">
            <div className="display-card business-block">
              <div>
                <img src={development} />
              </div>
              <h3>Web Develoment</h3>
              <p>
                I use technology and computer languages to design, develop, and
                maintain websites which are simultaneously visually appealing
                and functional.
              </p>
              <p className="read-more">Read More</p>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-xl-6 col-12 mb-3">
            <div
              className="display-card business-block"
              // onClick={() => {
              //   setTitle("Web Deployment");
              //   setData(
              //     "Most photographers love to shoot the unusual, and you don’t get much more unusual than These Unprecedented Times.Most photographers love to shoot the unusual, and you don’t get much more unusual than These Unprecedented Times."
              //   );
              //   handleShow();
              // }}
            >
              <div>
                <img src={deployment} />
              </div>
              <h3>Web Deployment</h3>
              <p>
                I work on server configuration, end-user functionality
                assurance, and making a website or web application publicly
                accessible on the internet.
              </p>
              <p className="read-more">Read More</p>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-xl-6 col-12 mb-3">
            <div className="display-card business-block">
              <div>
                <img src={mmgt} />
              </div>
              <h3>Web Product Management</h3>
              <p>
                Ensuring alignment with corporate goals, user needs, and
                continual improvement to offer a great online experience is my
                responsibility.
              </p>
              <p className="read-more">Read More</p>
            </div>
          </div>
        </div>
      </div>
      {/* <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        show={modalShow}
        onHide={handleClose}
        centered
      >
        <Modal.Header>
          <div className="popClose-btn" onClick={handleClose}>
            <FaTimes />
          </div>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4>{title}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={image} alt={title} />
          <p>{data}</p>
        </Modal.Body>
      </Modal> */}
    </div>
  );
}
