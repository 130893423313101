import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import moment from "moment";

export default function Blogs() {
  const [articles, setArticles] = useState([]);
  useEffect(() => {
    fetch(
      "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@parajulimanish43"
    )
      .then((res) => res.json())
      .then((data) => setArticles(data.items));
  }, []);
  return (
    <div>
      <div className="container-fluid hero-container">
        <Breadcrumb nameone="blogs" nametwo="Latest Blogs" />
      </div>
      <div className="container-fluid my-5">
        {articles?.length >= 1 ? (
          <>
            <div className="row">
              {articles?.map((article) => (
                <div
                  className="ccol-xxl-6 col-xl-6 col-xl-6 col-12 mb-3"
                  key={article.guid}
                >
                  <div className="display-card">
                    <h5>{article.title}</h5>
                    <p>
                      Published on:{" "}
                      {moment(article.pubDate).format("MMM Do YYYY")}
                    </p>
                    <p>Author: {article.author}</p>
                    <a
                      href={article.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Read more
                    </a>
                  </div>
                </div>
              ))}
              {/*  */}
            </div>
          </>
        ) : (
          <h5>There are currently no blogs available.</h5>
        )}
      </div>
    </div>
  );
}
