import React from "react";

import contactmap from "../../../assets/images/laptop.jpg";
export default function ContactMap() {
  return (
    <div className="mt-4 contact-container">
      <img src={contactmap} alt="contact-map" className="contact-img" />
      <div className="contact-form">
        <div className="contact-form-text">
          <h2 className="contact-form-font ">Reach Out Today.</h2>
          <p className="contact-form-font ">
            For code support, hiring and general queries.
          </p>
          <a
            href="mailto:parajulimanish43@gmail.com"
            className="contact-form-text-link"
          >
            parajulimanish43@gmail.com
          </a>
          <br />
        </div>
      </div>
    </div>
  );
}
