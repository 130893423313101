import React from "react";
import Breadcrumb from "../../components/Breadcrumb";
import AboutIntro from "./partials/AboutIntro";
import BasicDesc from "./partials/BasicDesc";
import DownloadCv from "./partials/DownloadCv";
import ExperienceTimeline from "./partials/ExperienceTimeline";
// import KnowledgeBox from "./partials/KnowledgeBox";

export default function About() {
  return (
    <>
      <div className="container-fluid hero-container">
        <Breadcrumb nameone="About" nametwo="About Me" />
        <AboutIntro />
        <hr />
        {/* <BasicDesc /> */}
        {/* <hr /> */}
        {/* <DownloadCv /> */}
        {/* <KnowledgeBox /> */}
        <ExperienceTimeline />
      </div>
    </>
  );
}
