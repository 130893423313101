import React from "react";
import image1 from "../../../assets/images/manish.jpg";
import { IoLocationSharp } from "react-icons/io5";
import { MdEmail } from "react-icons/md";

export default function AboutIntro() {
  return (
    <div className="container-fluid mt-5">
      <div className="row">
        <div className="col-xxl-6 col-xl-6 col-xl-6 col-12 mb-3">
          <img src={image1} alt="Avatar" className="rectangular-image" />
        </div>
        {/* <h3>Manish Parajuli</h3> */}
        <div className="col-xxl-6 col-xl-6 col-xl-6 col-12 my-5">
          <h5>I Create Innovative, Scalable, and Secure Solutions</h5>
          <p>
            My passion for developing high-performing teams and producing
            outstanding software solutions has driven my career as a software
            engineer with over two years of expertise.
            <br></br>I have a track record of successfully managing challenging
            projects that improve business operations and spur growth, and I
            excel at developing scalable solutions.
          </p>
          <div className="basic-desc">
            <ul>
              <li>
              <IoLocationSharp /><span>Toronto, Canada</span>
              </li>
              <li>
              <MdEmail /><span>parajulimanish43@gmail.com</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
