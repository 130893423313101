import React from "react";
import "react-awesome-slider/dist/styles.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import html from "../../../assets/images/slider/html.webp";
import css from "../../../assets/images/slider/css.webp";
import js from "../../../assets/images/slider/javascript.png";
import react from "../../../assets/images/slider/react.png";
import node from "../../../assets/images/slider/node.png";
import vue from "../../../assets/images/slider/vue.png";
import laravel from "../../../assets/images/slider/laravel.png";
import jenkins from "../../../assets/images/slider/jenkins.webp";
import sql from "../../../assets/images/slider/sql.png";
import aws from "../../../assets/images/slider/aws.png";
import mongodb from "../../../assets/images/slider/mongodb.png";

export default function ServiceSlider() {
  var settings = {
    // dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    // centerMode: true,
    // adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 2000,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 2000,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 2000,
        },
      },
    ],
  };
  return (
    <div className="my-5">
      <Slider {...settings}>
        {/* <div>
          <img src={html} alt="image1" className="slider-iamge" />
        </div>
        <div>
          <img src={css} alt="image1" className="slider-iamge" />
        </div> */}
        <div>
          <img src={js} alt="image1" className="slider-iamge" />
        </div>
        <div>
          <img src={react} alt="image1" className="slider-iamge" />
        </div>
        <div>
          <img src={node} alt="image1" className="slider-iamge" />
        </div>
        <div>
          <img src={vue} alt="image1" className="slider-iamge" />
        </div>
        <div>
          <img src={laravel} alt="image1" className="slider-iamge" />
        </div>
        <div>
          <img src={sql} alt="image1" className="slider-iamge" />
        </div>
        <div>
          <img src={mongodb} alt="image1" className="slider-iamge" />
        </div>
        <div>
          <img src={jenkins} alt="image1" className="slider-iamge" />
        </div>
        <div>
          <img src={aws} alt="image1" className="slider-iamge" />
        </div>
      </Slider>
    </div>
  );
}
