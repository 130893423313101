import React from "react";
import Breadcrumb from "../../components/Breadcrumb";
import ServiceCount from "./partials/ServiceCount";
import ServiceList from "./partials/ServiceList";
import ServiceSlider from "./partials/ServiceSlider";

export default function Service() {
  return (
    <>
      <div className="container-fluid hero-container">
        <Breadcrumb nameone="Services" nametwo="What I Do" />
        <ServiceList />
        <ServiceSlider />
        {/* <ServiceCount /> */}
      </div>
    </>
  );
}
