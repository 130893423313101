import React from "react";

export default function Breadcrumb({ nameone, nametwo }) {
  return (
    <div className="breadcrumb">
      {/* <button className="page-box">{nameone}</button> */}
      <h2>{nametwo}</h2>
      <span>{nametwo}</span>
    </div>
  );
}
