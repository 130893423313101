import React from "react";
import Breadcrumb from "../../components/Breadcrumb";
import ContactForm from "./partials/ContactForm";
import ContactMap from "./partials/ContactMap";

export default function Contact() {
  return (
    <div>
      <div className="container-fluid hero-container">
        <Breadcrumb nameone="contact" nametwo="Get in Touch" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <ContactForm />
          </div>
          <div className="col-lg-6">
            <ContactMap />
          </div>
        </div>
      </div>
    </div>
  );
}
