import { Link, NavLink } from "react-router-dom";
import {
  FaHome,
  FaUserAlt,
  FaChalkboard,
  FaEnvelope,
  FaListUl,
  FaRegSun,
} from "react-icons/fa";
// import image1 from "../../assets/images/manish.jpg";
import image1 from "../assets/images/manish.jpg";

const Sidebar = () => {
  return (
    <>
      <div className="vertical-flex sidebar">
        {/* <h2>Manish</h2> */}
        <div className="user-img">
          <img src={image1} alt="Avatar" className="circular-image" />
        </div>
        <hr />
        <ul>
          <li>
            <NavLink
              to="/"
              className={({ isActive, isPending }) =>
                isActive ? "active" : ""
              }
            >
              <FaHome className="icon" />
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/about"
              className={({ isActive, isPending }) =>
                isActive ? "active" : ""
              }
            >
              <FaUserAlt className="icon" />
              About
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/service"
              className={({ isActive, isPending }) =>
                isActive ? "active" : ""
              }
            >
              <FaRegSun className="icon" />
              Service
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/portfolio"
              className={({ isActive, isPending }) =>
                isActive ? "active" : ""
              }
            >
              <FaListUl className="icon" />
              Portfolio
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/blogs"
              className={({ isActive, isPending }) =>
                isActive ? "active" : ""
              }
            >
              <FaChalkboard className="icon" />
              Blogs
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contact"
              className={({ isActive, isPending }) =>
                isActive ? "active" : ""
              }
            >
              <FaEnvelope className="icon" />
              Contact
            </NavLink>
          </li>
        </ul>
        <p className="mt-4">© 2023 SEVEN TECH</p>
      </div>
    </>
  );
};

export default Sidebar;
