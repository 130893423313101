import React from "react";
import Breadcrumb from "../../components/Breadcrumb";
import PortfolioList from "./partials/PortfolioList";

export default function Portfolio() {
  return (
    <>
      <div className="container-fluid hero-container">
        <Breadcrumb nameone="portfolio" nametwo="My Works" />
      </div>
      <PortfolioList />
    </>
  );
}
