import { useForm, ValidationError } from "@formspree/react";
import React from "react";
import { toast } from "react-toastify";

export default function ContactForm() {
  const [state, handleSubmit] = useForm("xrbzkgab");
  if (state.succeeded) {
    toast.dark("Message sent  successfully");
    document.getElementById("myForm").reset();
  }

  return (
    <div className="mt-5">
      <div className="container-fluid my-3">
        <div className="row mt-3">
          <form id="myForm" onSubmit={handleSubmit}>
            <div className="col-xxl-12 col-xl-12 col-xl-12 col-12  form-field">
              <label htmlFor="email">Enter your name*</label>
              <input id="name" type="text" name="name" required />
            </div>
            <div className="col-xxl-12 col-xl-12 col-xl-12 col-12 form-field">
              <label htmlFor="email">Enter your email*</label>
              <input
                id="email"
                type="email"
                name="email"
                required
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$"
              />
            </div>
            <ValidationError
              prefix="Email"
              field="email"
              errors={state.errors}
            />
            <div className="col-xxl-12 col-xl-12 col-xl-12 col-12 form-field ">
              <label htmlFor="email">Enter your subject*</label>
              <input id="subject" type="text" name="subject" required />
            </div>
            <div className="col-xxl-12 col-xl-12 col-xl-12 col-12 form-field">
              <label htmlFor="email">Enter your message*</label>
              <textarea id="message" name="message" cols="30" required />
            </div>
            <ValidationError
              prefix="Message"
              field="message"
              errors={state.errors}
            />
            <div className="col-xxl-12 col-xl-12 col-xl-12 col-12 my-3">
              <button type="submit" disabled={state.submitting}>
                Send Mail
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
