import React from "react";
import {
  FaAngleUp,
  FaBullseye,
  FaGraduationCap,
  FaLaptop,
} from "react-icons/fa";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

export default function ExperienceTimeline() {
  return (
    <div>
      <div className="mt-5">
        <h2 className="text-center my-5"> Working Experience</h2>
        <VerticalTimeline>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "black", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid  black" }}
            date="Aug 2023 - present"
            iconStyle={{ background: "black", color: "#fff" }}
            icon={<FaLaptop />}
          >
            <div className="vertical-timeline-element-title">
              Freelance Web Developer
            </div>
            <div className="vertical-timeline-element-subtitle">Freelance</div>
            <p>Web product development, web page slicing, project guidance</p>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "black", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid  black" }}
            date="Jul 2022 - Jul 2023 A.D"
            iconStyle={{ background: "black", color: "#fff" }}
            icon={<FaLaptop />}
          >
            <div className="vertical-timeline-element-title">
              Associate Software Engineer
            </div>
            <div className="vertical-timeline-element-subtitle">
              CloudTech Pvt Ltd
            </div>
            <p>MERN based software development, software management.</p>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "black", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid  black" }}
            date="Apr 2022 - Jul 2022 A.D"
            iconStyle={{ background: "black", color: "#fff" }}
            icon={<FaLaptop />}
          >
            <div className="vertical-timeline-element-title">
              Frontend Developer Intern
            </div>
            <div className="vertical-timeline-element-subtitle">
              CloudTech Pvt Ltd
            </div>
            <p>Full Stack Software Develoment using Javascript.</p>
          </VerticalTimelineElement>
        </VerticalTimeline>

        <h2 className="text-center my-5">Educational Qualifications </h2>
        <VerticalTimeline>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "black", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid  black" }}
            date="Sept 2023 - present"
            iconStyle={{ background: "black", color: "#fff" }}
            icon={<FaGraduationCap />}
          >
            <div className="vertical-timeline-element-title">
              Networking and Cloud Computing
            </div>
            <div className="vertical-timeline-element-subtitle">
              Lambton College of Technology, Canada
            </div>
            <p>Cloud based networking, advance cloud computing.</p>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "black", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid  black" }}
            date="2017 - 2022 A.D"
            iconStyle={{ background: "black", color: "#fff" }}
            icon={<FaGraduationCap />}
          >
            <div className="vertical-timeline-element-title">Bsc. CSIT</div>
            <div className="vertical-timeline-element-subtitle">
              Trinity Int. College
            </div>
            <p>Web Development, Cloud Computing, Computer Networking, etc.</p>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            contentStyle={{ background: "black", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid  black" }}
            date="2017 A.D"
            iconStyle={{ background: "black", color: "#fff" }}
            icon={<FaGraduationCap />}
          >
            <div className="vertical-timeline-element-title">
              Intermediate +2
            </div>
            <div className="vertical-timeline-element-subtitle">
              Tulsi Higher Secondary Boarding School
            </div>
            <p>Science as major, with computer course.</p>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            iconStyle={{ background: "black", color: "#fff" }}
            icon={<FaAngleUp />}
          />
        </VerticalTimeline>
      </div>
    </div>
  );
}
